import {
  RlmExtendedMenuItem,
  RlmMe,
  RlmRole,
  RlmRouteType,
  RlmRoutingPath,
} from '@wgi/rlm/common';

export const constructGasSupplyEmergencyEnwgMenuItems = (
  rlmMe: RlmMe
): Array<RlmExtendedMenuItem> => {
  const menuItems: Array<RlmExtendedMenuItem> = [];
  if (
    [
      RlmRole.OPERATOR_CRISIS_MANAGER,
      RlmRole.OPERATOR_GLOBAL_SUPER_USER,
      RlmRole.OPERATOR_CUSTOMER_MANAGER,
      RlmRole.OPERATOR_CUSTOMER_SUPPORT,
    ].includes(rlmMe.role)
  ) {
    menuItems.push(
      {
        label: 'Reduktions-Priorisierung',
        id: 'menu-item-consumption-reduction-request',
        icon: 'pi pi-fw pi-list',
        routerLink: [
          RlmRoutingPath.ROOT,
          RlmRoutingPath.MAIN,
          RlmRoutingPath.CRISIS,
          RlmRoutingPath.CONSUMPTION_REDUCTION_REQUEST,
        ],
        routeType: RlmRouteType.INTERNAL,
      },
      {
        label: 'Einschaltfreigabe',
        id: 'menu-item-consumption-reduction-revert-request',
        icon: 'pi pi-fw pi-play',
        routerLink: [
          RlmRoutingPath.ROOT,
          RlmRoutingPath.MAIN,
          RlmRoutingPath.CRISIS,
          RlmRoutingPath.CONSUMPTION_REDUCTION_REVERT_REQUEST,
        ],
        routeType: RlmRouteType.INTERNAL,
      }
    );
  }
  return menuItems;
};
