export const insertIntoArrayAtIndex = <T>(
  array: Array<T>,
  index: number,
  ...newItems: Array<T>
): Array<T> => [
  // part of the array before the specified index
  ...array.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...array.slice(index),
];
