import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, tap } from 'rxjs';
import { RlmExtendedMenuItem } from '@wgi/rlm/common';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private readonly _ngOnDestroySubject$: Subject<void> = new Subject<void>();

  private readonly _menuSourceSubject$: Subject<string> = new Subject<string>();
  public readonly menuSource$: Observable<string> =
    this._menuSourceSubject$.asObservable();
  private readonly _resetSourceSubject$: Subject<boolean> =
    new Subject<boolean>();
  public readonly resetSource$: Observable<boolean> =
    this._resetSourceSubject$.asObservable();

  private readonly _topRlmExtendedMenuItemSubject$: BehaviorSubject<RlmExtendedMenuItem> =
    new BehaviorSubject<RlmExtendedMenuItem>(undefined);
  private readonly _topRlmExtendedMenuItemSubscription?: Subscription;

  constructor() {
    this._topRlmExtendedMenuItemSubscription =
      this._topRlmExtendedMenuItemSubject$
        .pipe(
          filter(
            (topRlmExtendedMenuItems: RlmExtendedMenuItem) =>
              topRlmExtendedMenuItems !== undefined
          ),
          tap((topRlmExtendedMenuItems: RlmExtendedMenuItem) => {
            this._topRlmExtendedMenuItem = topRlmExtendedMenuItems;
          }),
          takeUntil(this._ngOnDestroySubject$)
        )
        .subscribe();
  }

  private _topRlmExtendedMenuItem: RlmExtendedMenuItem;

  public get topRlmExtendedMenuItem(): RlmExtendedMenuItem {
    return this._topRlmExtendedMenuItem;
  }

  public set topRlmExtendedMenuItem(
    topRlmExtendedMenuItems: RlmExtendedMenuItem
  ) {
    this._topRlmExtendedMenuItemSubject$.next(topRlmExtendedMenuItems);
  }

  public get topRlmExtendedMenuItem$(): Observable<RlmExtendedMenuItem> {
    return this._topRlmExtendedMenuItemSubject$.asObservable();
  }

  public ngOnDestroy(): void {
    this._ngOnDestroySubject$.next();
    this._ngOnDestroySubject$.complete();
    this._topRlmExtendedMenuItemSubscription.unsubscribe();
  }

  public onMenuStateChange(key: string): void {
    this._menuSourceSubject$.next(key);
  }

  public reset(): void {
    this._resetSourceSubject$.next(true);
  }
}
