import {
  RlmCurrentGasSupplyEmergencyResponse,
  RlmMeasureMode,
} from '@wgi/rlm/common';
import { BehaviorSubject, Observable, Subject, Subscription, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class SupplyShortageState {
  private readonly _rlmCurrentGasSupplyEmergencyResponseSubject$: BehaviorSubject<
    RlmCurrentGasSupplyEmergencyResponse | undefined
  > = new BehaviorSubject<RlmCurrentGasSupplyEmergencyResponse | undefined>(
    undefined
  );
  private readonly _rlmCurrentGasSupplyEmergencyResponseSubscription?: Subscription;

  private readonly _selectedRlmMeasureModeSubject$: BehaviorSubject<
    RlmMeasureMode | undefined
  > = new BehaviorSubject<RlmMeasureMode | undefined>(undefined);
  private readonly _selectedRlmMeasureModeSubscription?: Subscription;

  private readonly _contactNameSubject$: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  private readonly _contactNameSubscription?: Subscription;

  private readonly _contactPhoneNumberSubject$: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject<string | undefined>(undefined);
  private readonly _contactPhoneNumberSubscription?: Subscription;

  private readonly _submittedSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _submittedSubscription?: Subscription;

  private readonly _loadingSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private readonly _loadingSubscription?: Subscription;

  constructor(private readonly _ngOnDestroySubject$: Subject<void>) {
    this._rlmCurrentGasSupplyEmergencyResponseSubscription =
      this._rlmCurrentGasSupplyEmergencyResponseSubject$
        .pipe(
          tap(
            (
              rlmCurrentGasSupplyEmergencyResponse: RlmCurrentGasSupplyEmergencyResponse
            ) =>
              (this._rlmCurrentGasSupplyEmergencyResponse =
                rlmCurrentGasSupplyEmergencyResponse)
          ),
          takeUntil(this._ngOnDestroySubject$)
        )
        .subscribe();

    this._selectedRlmMeasureModeSubscription =
      this._selectedRlmMeasureModeSubject$
        .pipe(
          tap(
            (selectedRlmMeasureMode: RlmMeasureMode) =>
              (this._selectedRlmMeasureMode = selectedRlmMeasureMode)
          ),
          takeUntil(this._ngOnDestroySubject$)
        )
        .subscribe();

    this._contactNameSubscription = this._contactNameSubject$
      .pipe(
        tap((contactName: string) => (this._contactName = contactName)),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();

    this._contactPhoneNumberSubscription = this._contactPhoneNumberSubject$
      .pipe(
        tap(
          (contactPhoneNumber: string) =>
            (this._contactPhoneNumber = contactPhoneNumber)
        ),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();

    this._submittedSubscription = this._submittedSubject$
      .pipe(
        tap((submitted: boolean) => (this._submitted = submitted)),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();

    this._loadingSubscription = this._loadingSubject$
      .pipe(
        tap((loading: boolean) => (this._loading = loading)),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();
  }

  private _loading: boolean = true;

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(loading: boolean) {
    this._loadingSubject$.next(loading);
  }

  public get loading$(): Observable<boolean> {
    return this._loadingSubject$.asObservable();
  }

  private _submitted: boolean = false;

  public get submitted(): boolean {
    return this._submitted;
  }

  public set submitted(submitted: boolean) {
    this._submittedSubject$.next(submitted);
  }

  public get submitted$(): Observable<boolean> {
    return this._submittedSubject$.asObservable();
  }

  private _contactName: string | undefined = undefined;

  public get contactName(): string {
    return this._contactName;
  }

  public set contactName(contactName: string) {
    this._contactNameSubject$.next(contactName);
  }

  private _contactPhoneNumber: string | undefined = undefined;

  public get contactPhoneNumber(): string {
    return this._contactPhoneNumber;
  }

  public set contactPhoneNumber(contactPhoneNumber: string) {
    this._contactPhoneNumberSubject$.next(contactPhoneNumber);
  }

  private _rlmCurrentGasSupplyEmergencyResponse:
    | RlmCurrentGasSupplyEmergencyResponse
    | undefined = undefined;

  public get rlmCurrentGasSupplyEmergencyResponse():
    | RlmCurrentGasSupplyEmergencyResponse
    | undefined {
    return this._rlmCurrentGasSupplyEmergencyResponse;
  }

  public set rlmCurrentGasSupplyEmergencyResponse(
    rlmCurrentGasSupplyEmergencyResponse: RlmCurrentGasSupplyEmergencyResponse
  ) {
    this._rlmCurrentGasSupplyEmergencyResponseSubject$.next(
      rlmCurrentGasSupplyEmergencyResponse
    );
  }

  public get rlmCurrentGasSupplyEmergencyResponse$(): Observable<
    RlmCurrentGasSupplyEmergencyResponse | undefined
  > {
    return this._rlmCurrentGasSupplyEmergencyResponseSubject$.asObservable();
  }

  private _selectedRlmMeasureMode: RlmMeasureMode | undefined = undefined;

  public get selectedRlmMeasureMode(): RlmMeasureMode | undefined {
    return this._selectedRlmMeasureMode;
  }

  public set selectedRlmMeasureMode(selectedRlmMeasureMode: RlmMeasureMode) {
    this._selectedRlmMeasureModeSubject$.next(selectedRlmMeasureMode);
  }

  public get selectedRlmMeasureMode$(): Observable<RlmMeasureMode | undefined> {
    return this._selectedRlmMeasureModeSubject$.asObservable();
  }

  public get contactName$(): Observable<string> {
    return this._contactNameSubject$.asObservable();
  }

  public get contactPhoneNumber$(): Observable<string> {
    return this._contactPhoneNumberSubject$.asObservable();
  }

  public tearDown(): void {
    this._rlmCurrentGasSupplyEmergencyResponseSubscription?.unsubscribe();
    this._selectedRlmMeasureModeSubscription?.unsubscribe();
    this._contactNameSubscription?.unsubscribe();
    this._submittedSubscription?.unsubscribe();
    this._loadingSubscription?.unsubscribe();
  }
}
