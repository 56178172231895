import { Injectable, OnDestroy } from '@angular/core';
import {
  catchError,
  defer,
  EMPTY,
  finalize,
  first,
  isObservable,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  take,
  tap,
} from 'rxjs';
import { SupplyShortageState } from '../classes/supply-shortage-state.class';
import { EnvironmentService } from './environment.service';
import {
  RlmBackendService,
  RlmCurrentGasSupplyEmergencyResponse,
  RlmExtendedMenuItem,
  RlmGasSupplyEmergencyView,
  rlmIsBlankOrWhiteSpace,
  RlmMe,
  RlmMeasureMode,
  rlmMeasureModeToShortDescriptionMap,
  rlmMeasureModeToToastDescriptionMap,
  RlmProfileService,
  RlmRole,
  RlmRoutingPath,
  RlmTopicEnum,
  RlmTopicService,
} from '@wgi/rlm/common';
import { takeUntil } from 'rxjs/operators';
import { CreateOrUpdateGasSupplyEmergencyRequest } from '../models/supply-shortage/create-or-update-gas-supply-emergency-request.model';
import { MessageService } from 'primeng/api';
import { MenuService } from './menu.service';
import { constructGasSupplyEmergencyGassvMenuItems } from '../constants/construct-gas-supply-emergency-gassv-menu-items.const';
import { constructGasSupplyEmergencyEnwgMenuItems } from '../constants/construct-gas-supply-emergency-enwg-menu-items.const';
import { insertIntoArrayAtIndex } from '../constants/insert-into-array-at-index.const';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SupplyShortageStateService implements OnDestroy {
  private readonly _ngOnDestroySubject$: Subject<void> = new Subject<void>();
  private readonly _supplyShortageState: SupplyShortageState;

  private readonly _apiUrl: string = `${this._environmentService.environment.feEnvironmentBackendUrl}/emergency-management/gas-supply-emergency`;

  private _shouldSeeGasSupplyEmergencyMenuEntries: boolean = false;

  constructor(
    private readonly _environmentService: EnvironmentService,
    private readonly _rlmBackendService: RlmBackendService,
    private readonly _messageService: MessageService,
    private readonly _menuService: MenuService,
    private readonly _rlmTopicService: RlmTopicService,
    private readonly _rlmProfileService: RlmProfileService
  ) {
    this._supplyShortageState = new SupplyShortageState(
      this._ngOnDestroySubject$
    );

    this._rlmProfileService.me$
      .pipe(
        tap(
          (rlmMe: RlmMe) =>
            (this._shouldSeeGasSupplyEmergencyMenuEntries =
              rlmMe.role === RlmRole.OPERATOR_GLOBAL_SUPER_USER ||
              rlmMe.role === RlmRole.OPERATOR_READ_ONLY ||
              rlmMe.role === RlmRole.OPERATOR_CUSTOMER_SUPPORT ||
              rlmMe.role === RlmRole.OPERATOR_APPLICATION_MANAGEMENT ||
              rlmMe.role === RlmRole.OPERATOR_CRISIS_MANAGER ||
              rlmMe.role === RlmRole.OPERATOR_CUSTOMER_MANAGER)
        ),
        take(1),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();
  }

  private _currentGasSupplyEmergency$: Observable<RlmCurrentGasSupplyEmergencyResponse>;

  public get currentGasSupplyEmergency$(): Observable<RlmCurrentGasSupplyEmergencyResponse> {
    if (!this._currentGasSupplyEmergency$) {
      // https://twitter.com/KiraSudarushkin/status/1268574519230435329
      let shared$: Observable<RlmCurrentGasSupplyEmergencyResponse> | undefined;

      const createShared =
        (): Observable<RlmCurrentGasSupplyEmergencyResponse> =>
          shared$ ??
          (shared$ = this._rlmBackendService
            .getEntry$<RlmCurrentGasSupplyEmergencyResponse>(`${this._apiUrl}`)
            .pipe(
              shareReplay({
                ...{ bufferSize: 1, refCount: false, windowTime: 60000 },
              })
            ));

      this._currentGasSupplyEmergency$ = defer(createShared).pipe(
        first(
          null,
          defer(() => {
            shared$ = undefined;
            return createShared();
          })
        ),
        mergeMap(
          (
            d$:
              | RlmCurrentGasSupplyEmergencyResponse
              | Observable<RlmCurrentGasSupplyEmergencyResponse>
          ) => (isObservable(d$) ? d$ : of(d$))
        )
      );
    }

    return this._currentGasSupplyEmergency$;
  }

  public get rlmCurrentGasSupplyEmergencyResponse():
    | RlmCurrentGasSupplyEmergencyResponse
    | undefined {
    return this._supplyShortageState.rlmCurrentGasSupplyEmergencyResponse;
  }

  public set rlmCurrentGasSupplyEmergencyResponse(
    rlmCurrentGasSupplyEmergencyResponse: RlmCurrentGasSupplyEmergencyResponse
  ) {
    this._supplyShortageState.rlmCurrentGasSupplyEmergencyResponse =
      rlmCurrentGasSupplyEmergencyResponse;
  }

  public get rlmCurrentGasSupplyEmergencyResponse$(): Observable<
    RlmCurrentGasSupplyEmergencyResponse | undefined
  > {
    return this._supplyShortageState.rlmCurrentGasSupplyEmergencyResponse$;
  }

  public get selectedRlmMeasureMode(): RlmMeasureMode | undefined {
    return this._supplyShortageState.selectedRlmMeasureMode;
  }

  public set selectedRlmMeasureMode(selectedRlmMeasureMode: RlmMeasureMode) {
    this._supplyShortageState.selectedRlmMeasureMode = selectedRlmMeasureMode;
  }

  public get selectedRlmMeasureMode$(): Observable<RlmMeasureMode | undefined> {
    return this._supplyShortageState.selectedRlmMeasureMode$;
  }

  public get contactName(): string {
    return this._supplyShortageState.contactName;
  }

  public set contactName(contactName: string) {
    this._supplyShortageState.contactName = contactName;
  }

  public get contactPhoneNumber(): string {
    return this._supplyShortageState.contactPhoneNumber;
  }

  public set contactPhoneNumber(contactPhoneNumber: string) {
    this._supplyShortageState.contactPhoneNumber = contactPhoneNumber;
  }

  public get contactName$(): Observable<string> {
    return this._supplyShortageState.contactName$;
  }

  public get contactPhoneNumber$(): Observable<string> {
    return this._supplyShortageState.contactPhoneNumber$;
  }

  public get submitted(): boolean {
    return this._supplyShortageState.submitted;
  }

  public set submitted(submitted: boolean) {
    this._supplyShortageState.submitted = submitted;
  }

  public get submitted$(): Observable<boolean> {
    return this._supplyShortageState.submitted$;
  }

  public get loading(): boolean {
    return this._supplyShortageState.loading;
  }

  public set loading(loading: boolean) {
    this._supplyShortageState.loading = loading;
  }

  public get loading$(): Observable<boolean> {
    return this._supplyShortageState.loading$;
  }

  public updateCurrentGasSupplyEmergency(): void {
    this._updateCurrentGasSupplyEmergency$().subscribe();
  }

  public updateGasSupplyEmergency(updateContact: boolean = false): void {
    this.submitted = true;

    if (
      this.selectedRlmMeasureMode === RlmMeasureMode.MEASURE &&
      (rlmIsBlankOrWhiteSpace(this.contactName) ||
        rlmIsBlankOrWhiteSpace(this.contactPhoneNumber))
    )
      return;

    let createOrUpdateGasSupplyEmergencyRequest: CreateOrUpdateGasSupplyEmergencyRequest =
      {
        mode: this.selectedRlmMeasureMode,
      };

    if (this.selectedRlmMeasureMode === RlmMeasureMode.MEASURE) {
      createOrUpdateGasSupplyEmergencyRequest = {
        ...createOrUpdateGasSupplyEmergencyRequest,
        contactName: this.contactName,
        contactPhoneNumber: this.contactPhoneNumber,
      };
    }

    if (this.rlmCurrentGasSupplyEmergencyResponse?.emergency?.id)
      createOrUpdateGasSupplyEmergencyRequest = {
        ...createOrUpdateGasSupplyEmergencyRequest,
        id: this.rlmCurrentGasSupplyEmergencyResponse.emergency.id,
      };

    let gasSupplyEmergencyView$: Observable<RlmCurrentGasSupplyEmergencyResponse>;
    if (createOrUpdateGasSupplyEmergencyRequest.id) {
      gasSupplyEmergencyView$ = this._rlmBackendService.patchEntry$<
        CreateOrUpdateGasSupplyEmergencyRequest,
        RlmCurrentGasSupplyEmergencyResponse
      >(`${this._apiUrl}`, createOrUpdateGasSupplyEmergencyRequest);
    } else {
      gasSupplyEmergencyView$ = this._rlmBackendService.putEntry$<
        CreateOrUpdateGasSupplyEmergencyRequest,
        RlmCurrentGasSupplyEmergencyResponse
      >(`${this._apiUrl}`, createOrUpdateGasSupplyEmergencyRequest);
    }

    this.loading = true;

    // update and update currentGasSupplyShortage
    gasSupplyEmergencyView$
      .pipe(
        tap(
          (
            rlmCurrentGasSupplyEmergencyResponse: RlmCurrentGasSupplyEmergencyResponse
          ) => {
            this.rlmCurrentGasSupplyEmergencyResponse =
              rlmCurrentGasSupplyEmergencyResponse;
            this.submitted = false;
            this._messageService.add({
              severity: 'success',
              summary: 'Bestätigung',
              detail: updateContact
                ? `Die Kontaktdaten wurden erfolgreich gespeichert.`
                : `Versorgungsnotfall mit ${
                    rlmMeasureModeToToastDescriptionMap.has(
                      createOrUpdateGasSupplyEmergencyRequest.mode
                    )
                      ? rlmMeasureModeToToastDescriptionMap.get(
                          createOrUpdateGasSupplyEmergencyRequest.mode
                        )
                      : '-'
                  } ist aktiviert.`,
            });
          }
        ),
        catchError(() => {
          this._messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: updateContact
              ? `Die Kontaktdaten konnten nicht gespeichert werden.`
              : `Versorgungsnotfall mit ${
                  rlmMeasureModeToToastDescriptionMap.has(
                    createOrUpdateGasSupplyEmergencyRequest.mode
                  )
                    ? rlmMeasureModeToToastDescriptionMap.get(
                        createOrUpdateGasSupplyEmergencyRequest.mode
                      )
                    : '-'
                } konnte nicht aktiviert werden.`,
          });
          return EMPTY;
        }),
        tap(() => {
          this.updateMenuService();
        }),
        finalize((): void => {
          this.loading = false;
        }),
        take(1),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();
  }

  public endGasSupplyEmergency(): void {
    if (this.rlmCurrentGasSupplyEmergencyResponse)
      this._rlmBackendService
        .createEntry$<unknown, RlmGasSupplyEmergencyView>(
          `${this._apiUrl}/${this.rlmCurrentGasSupplyEmergencyResponse.emergency.id}/complete`,
          {}
        )
        .pipe(
          tap(() => {
            this._messageService.add({
              severity: 'success',
              summary: 'Bestätigung',
              detail: `Versorgungsnotfall mit ${
                rlmMeasureModeToToastDescriptionMap.has(
                  this.selectedRlmMeasureMode
                )
                  ? rlmMeasureModeToToastDescriptionMap.get(
                      this.selectedRlmMeasureMode
                    )
                  : '-'
              } ist beendet.`,
            });

            this.rlmCurrentGasSupplyEmergencyResponse = undefined;
            this.selectedRlmMeasureMode = undefined;
          }),
          catchError((error: unknown) => {
            let detail: string = `Versorgungsnotfall mit ${
              rlmMeasureModeToToastDescriptionMap.has(
                this.selectedRlmMeasureMode
              )
                ? rlmMeasureModeToToastDescriptionMap.get(
                    this.selectedRlmMeasureMode
                  )
                : '-'
            } konnte nicht beendet werden.`;

            if (error instanceof HttpErrorResponse && error.status === 424) {
              detail += ` Nicht alle ${
                rlmMeasureModeToShortDescriptionMap.has(
                  this.selectedRlmMeasureMode
                )
                  ? rlmMeasureModeToShortDescriptionMap.get(
                      this.selectedRlmMeasureMode
                    )
                  : '-'
              } sind abgeschlossen worden.`;
            }

            this._messageService.add({
              severity: 'error',
              summary: 'Fehler',
              detail: detail,
            });

            return EMPTY;
          }),
          tap(() => {
            this.updateMenuService();
          }),
          finalize((): void => {
            this.loading = false;
          }),
          take(1),
          takeUntil(this._ngOnDestroySubject$)
        )
        .subscribe();
  }

  public ngOnDestroy(): void {
    this._ngOnDestroySubject$.next();
    this._ngOnDestroySubject$.complete();
    this._supplyShortageState.tearDown();
  }

  public updateMenuService(): void {
    if (this._rlmTopicService.rlmTopicEnum === RlmTopicEnum.HYDROGEN) return;
    this._rlmProfileService.me$
      .pipe(
        tap((rlmMe: RlmMe) => {
          const rlmExtendedMenuItem: RlmExtendedMenuItem =
            this._menuService.topRlmExtendedMenuItem;
          const rlmExtendedMenuItemSupplyShortageIndex: number =
            rlmExtendedMenuItem.items.findIndex(
              (rlmExtendedMenuItem: RlmExtendedMenuItem) =>
                (<Array<string>>rlmExtendedMenuItem.routerLink).includes(
                  RlmRoutingPath.SUPPLY_SHORTAGE
                )
            );

          let rlmExtendedMenuItemsToInsert: Array<RlmExtendedMenuItem>;
          if (
            this.rlmCurrentGasSupplyEmergencyResponse?.emergency?.mode ===
            RlmMeasureMode.MEASURE
          )
            rlmExtendedMenuItemsToInsert =
              constructGasSupplyEmergencyGassvMenuItems(rlmMe);

          if (
            this.rlmCurrentGasSupplyEmergencyResponse?.emergency?.mode ===
            RlmMeasureMode.ADJUSTMENT
          )
            rlmExtendedMenuItemsToInsert =
              constructGasSupplyEmergencyEnwgMenuItems(rlmMe);

          // insert default menu
          if (
            JSON.parse(
              this._environmentService.environment
                .feEnvironmentFeatureFederalSupplyShortageOverride
            ) === false
          )
            rlmExtendedMenuItemsToInsert =
              constructGasSupplyEmergencyEnwgMenuItems(rlmMe);

          const menuItemsAlreadyContained: boolean =
            rlmExtendedMenuItemsToInsert?.some(
              (rlmExtendedMenuItemsToInsertItem: RlmExtendedMenuItem) =>
                rlmExtendedMenuItem?.items?.includes(
                  rlmExtendedMenuItem?.items?.find(
                    (rlmExtendedMenuItemItem: RlmExtendedMenuItem) =>
                      JSON.stringify(rlmExtendedMenuItemsToInsertItem) ===
                      JSON.stringify(rlmExtendedMenuItemItem)
                  )
                )
            );

          // insert new menu items depending on topic below SUPPLY_SHORTAGE
          if (
            rlmExtendedMenuItemsToInsert &&
            !menuItemsAlreadyContained &&
            this._shouldSeeGasSupplyEmergencyMenuEntries
          )
            this._menuService.topRlmExtendedMenuItem = {
              ...rlmExtendedMenuItem,
              items: insertIntoArrayAtIndex<RlmExtendedMenuItem>(
                rlmExtendedMenuItem.items,
                rlmExtendedMenuItemSupplyShortageIndex + 1,
                ...rlmExtendedMenuItemsToInsert
              ),
            };

          // remove menu items that should not be visible
          if (!rlmExtendedMenuItemsToInsert)
            this._menuService.topRlmExtendedMenuItem = {
              ...rlmExtendedMenuItem,
              items: [
                ...rlmExtendedMenuItem.items.filter(
                  (rlmExtendedMenuItem: RlmExtendedMenuItem) =>
                    this._routesToRemove(rlmExtendedMenuItem)
                ),
              ],
            };
        }),
        take(1),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();
  }

  private _updateCurrentGasSupplyEmergency$(): Observable<RlmCurrentGasSupplyEmergencyResponse> {
    this.loading = true;
    return this._rlmBackendService
      .getEntry$<RlmCurrentGasSupplyEmergencyResponse>(`${this._apiUrl}`)
      .pipe(
        tap(
          (
            rlmCurrentGasSupplyEmergencyResponse: RlmCurrentGasSupplyEmergencyResponse
          ) => {
            this.rlmCurrentGasSupplyEmergencyResponse =
              rlmCurrentGasSupplyEmergencyResponse ?? undefined;
            this.selectedRlmMeasureMode =
              rlmCurrentGasSupplyEmergencyResponse?.emergency?.mode ??
              undefined;
            this.contactName =
              rlmCurrentGasSupplyEmergencyResponse?.emergency?.contactName ??
              undefined;
            this.contactPhoneNumber =
              rlmCurrentGasSupplyEmergencyResponse?.emergency
                ?.contactPhoneNumber ?? undefined;
          }
        ),
        tap(() => {
          if (
            <boolean>(
              JSON.parse(
                this._environmentService.environment
                  .feEnvironmentFeatureFederalSupplyShortageOverride
              )
            )
          )
            this.updateMenuService();
        }),
        finalize((): void => {
          this.loading = false;
        }),
        take(1),
        takeUntil(this._ngOnDestroySubject$)
      );
  }

  private _routesToRemove(rlmExtendedMenuItem: RlmExtendedMenuItem): boolean {
    return (
      !(<Array<string>>rlmExtendedMenuItem.routerLink).includes(
        RlmRoutingPath.MEASURES
      ) &&
      !(<Array<string>>rlmExtendedMenuItem.routerLink).includes(
        RlmRoutingPath.VIOLATIONS
      ) &&
      !(<Array<string>>rlmExtendedMenuItem.routerLink).includes(
        RlmRoutingPath.CONSUMPTION_REDUCTION_REQUEST
      ) &&
      !(<Array<string>>rlmExtendedMenuItem.routerLink).includes(
        RlmRoutingPath.CONSUMPTION_REDUCTION_REVERT_REQUEST
      )
    );
  }
}
