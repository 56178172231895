import {
  RlmExtendedMenuItem,
  RlmMe,
  RlmRole,
  RlmRouteType,
  RlmRoutingPath,
} from '@wgi/rlm/common';

export const constructGasSupplyEmergencyGassvMenuItems = (
  rlmMe: RlmMe
): Array<RlmExtendedMenuItem> => {
  const menuItems: Array<RlmExtendedMenuItem> = [
    {
      label: 'Verfügungen',
      id: 'menu-item-measures',
      icon: 'pi pi-fw pi-tag',
      routerLink: [
        RlmRoutingPath.ROOT,
        RlmRoutingPath.MAIN,
        RlmRoutingPath.CRISIS,
        RlmRoutingPath.MEASURES,
      ],
      routeType: RlmRouteType.INTERNAL,
    },
  ];

  if (
    [
      RlmRole.OPERATOR_CRISIS_MANAGER,
      RlmRole.OPERATOR_GLOBAL_SUPER_USER,
      RlmRole.OPERATOR_CUSTOMER_MANAGER,
    ].includes(rlmMe.role)
  ) {
    menuItems.push({
      label: 'Verstöße',
      id: 'menu-item-violations',
      icon: 'pi pi-fw pi-bolt',
      routerLink: [
        RlmRoutingPath.ROOT,
        RlmRoutingPath.MAIN,
        RlmRoutingPath.CRISIS,
        RlmRoutingPath.VIOLATIONS,
      ],
      routeType: RlmRouteType.INTERNAL,
    });
  }
  return menuItems;
};
